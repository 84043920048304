import React, { useEffect, useState, useContext } from "react";
// import { Link } from "react-router-dom";
import Footer from "../components/Footer.js";
import NavbarInner from "../components/Navbar-Inner.js";
import NavbarFixed from "../components/Navbar-Fixed.js";
import SocketContext from "../context/SocketContext";
import { useDispatch, useSelector } from "react-redux";

// Images
// import copy_icon from "../assets/images/copy_icon.svg";

// import components
import Trade from "../components/Trade";
import { getSiteSetting } from "../api/users.js";
import { updateTradeAsset } from "../api/tradeAction";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function TradePage(props) {
  const [siteDoc, setSiteDoc] = useState({});
  const socketContext = useContext(SocketContext);
  const dispatch = useDispatch();

  const fetchData = async () => {
    let { status, result } = await getSiteSetting();
    if (status == "success") {
      setSiteDoc(result);
    }
  };

  // useEffect(() => {
  //   // socket
  //   socketContext.socket.on("updateTradeAsset", (result) => {
  //     // console.log(result, 'resultresult222333333322222')
  //     updateTradeAsset(dispatch, result);
  //   });
  //   // fetchData();
  // }, []);
  
  useEffect(() => {
    // return () => {
    //   socketContext.socket.off("marketPrice");
    //   // socketContext.socket.off("orderBook");
    //   // socketContext.socket.off("recentTrade");
    // };
  }, [])

  return (
    <div>
      <ScrollToTopOnMount />
      <NavbarInner />
      <div className="innerpages_wrapper">
        <div className="container-fluid">
          {/* <div className="inner_title_wrapper inner_title_wrapper_flex justify-content-between align-items-center flex-wrap">
            <h2 className="inner_title">Trade</h2>
          </div> */}
          <Trade />
        </div>
      </div>
      <Footer />
    </div>
  );
}
