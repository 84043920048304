import React, { useState, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.svg";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import CurrencyFormat from 'react-currency-format';
// import api
import { logout } from "../api/users";

//import lib
import { toFixedDown, truncateDecimals } from "../lib/roundOf";
import isEmpty from "../lib/isEmpty";

//import modal
import ProfileUpdateModal from "./AccountSettings/ProfileModal"
import { getAssetData, setAvailableBal } from "../api/walletAction";
import { toastAlert } from "../lib/toastAlert";
import { getStackingEstimate } from "../api/staking";

const NavbarInner = () => {
  // hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // redux-state
  const { notifiyCount } = useSelector((state) => state.notice);
  const currency = useSelector((state) => state.currency);
  const walletData = useSelector((state) => state.wallet);
  const priceConversion = useSelector((state) => state.priceConversion);
  const { isStake, isTrade, isLiveEvents } = useSelector((state) => state.account);

  //state
  const [available, setTotalAvailable] = useState(0);
  const [tradedata, setTradeData] = useState()
  const [lockBal, setlockBal] = useState(0);

  const [ProfileModal, setProfileModal] = useState(false);

  //function
  function loadScript() {
    // Navbar Sticky
    var t = $(".navbar-sticky");
    $(window).on("scroll load", function (e) {
      var e = $(window).scrollTop();
      120 <= e
        ? t.addClass("navbar-sticky--moved-up")
        : t.removeClass("navbar-sticky--moved-up");
      250 <= e
        ? t.addClass("navbar-sticky--transitioned")
        : t.removeClass("navbar-sticky--transitioned");
      500 <= e
        ? t.addClass("navbar-sticky--on")
        : t.removeClass("navbar-sticky--on");
    });

    $(document).ready(function () {
      $(".nav_after_login li a").click(function () {
        $("body").css({ overflow: "unset" });
      });
    });
  }


  const handleAsset = async () => {
    try {
      let totalAmount = 0;
      let lockedBal = 0;
      let tempArr = [...walletData];
      currency?.length >= 0 &&
        currency.map((item, index) => {
          let PriceCnv = priceConversion.find(
            (el) =>
              el.baseSymbol == item.currencySymbol && el.convertSymbol == "USD"
          );
          let pairIndex =
            tempArr &&
            tempArr.findIndex((el) => {
              return el._id == item._id;
            });
          if (pairIndex >= 0 && !isEmpty(pairIndex)) {
            tempArr[pairIndex] = {
              ...tempArr[pairIndex],
              ...{
                // image: item.image,
                // decimals: item.decimals,
                // status: item.status,
                // lockedBal: !isEmpty(PriceCnv?.convertPrice)
                //   ? parseFloat(tempArr[pairIndex].lockedBal) *
                //   parseFloat(PriceCnv.convertPrice)
                //   : parseFloat(tempArr[pairIndex].lockedBal),

                availableBal: !isEmpty(PriceCnv?.convertPrice)
                  ? parseFloat(tempArr[pairIndex].stakeBal - tempArr[pairIndex].lockedBal) *
                  parseFloat(PriceCnv.convertPrice)
                  : parseFloat(tempArr[pairIndex].stakeBal - tempArr[pairIndex].lockedBal),
              },
            };
            totalAmount += tempArr[pairIndex].availableBal;

          }
        });

      // let bal = tempArr.find((el) => el.coin === 'USDT')

      // let result = {
      //   totalBal: totalAmount,
      //   lockedBal: lockedBal,
      //   USDTBal: bal.stakeBal
      // }
      // await setAvailableBal(result, dispatch)
      setTotalAvailable(totalAmount);
    } catch (err) {
      console.log("err:------ ", err);
    }
  };

  const getStackinginfo = async (spotPairId, type) => {
    try {
      const { status, result } = await getStackingEstimate(spotPairId);
      if (status == "success") {
        setTradeData(result)
      } else {
      }
    } catch (err) {
      console.log(err, 'errro');
    }
  };


  const handleCloseModal = () => {
    setProfileModal(false);
  };

  useEffect(() => {
    if (
      !isEmpty(walletData) &&
      !isEmpty(currency) &&
      !isEmpty(priceConversion)
    ) {
      handleAsset();
      getStackinginfo()
    }
  }, [walletData, currency, priceConversion]);

  useEffect(() => {
    loadScript();
    // getAssetData(dispatch)
  }, []);

  const handleTrade = () => {
    if (!isTrade) {
      toastAlert("error", "Trade Access Blocked", "login");
    }

  }

  const handleStake = () => {
    if (!isStake) {
      toastAlert("error", "Stake Access Blocked", "login");
    }
  };
  const handleLiveEvent = () => {
    if (!isLiveEvents) {
      toastAlert("error", "Live Events Access Blocked", "login");
    }
  };
  return (
    <div className="container-fluid">
      <ProfileUpdateModal isShow={ProfileModal} onHide={handleCloseModal} />

      <nav className="navbar navbar-dark navbar-expand-xl main_navbar navbar-sticky">
        <Link className="navbar-brand" to="/">
          <img src={logo} className="img-fluid brand_logo" alt="logo" />
        </Link>
        <ul className="navbar-nav navbar_left w-100 justify-content-end align-items-center ms-3">
          <li className="nav-item d-flex align-items-center me-xl-3 me-xxl-5">
            <p className="mb-0 bal">
              <div className="tot" >Available Balance&nbsp;:</div><div> $  <CurrencyFormat displayType={'text'} thousandSeparator={true} value={truncateDecimals(available, 2)} /></div>
            </p>
          </li>
          {" "}
          <li className="nav-item d-flex align-items-center me-xl-3 me-xxl-5">
            <p className="mb-0 bal">
              <div className="tot" >Staked Balance&nbsp;:</div><div> $  <CurrencyFormat displayType={'text'} thousandSeparator={true} value={truncateDecimals(tradedata?.actStackToTalAmou, 2)} /></div>
            </p>
          </li>
          {" "}
          <li className="nav-item d-flex align-items-center me-auto">
            <p className="mb-0 bal">
              <div className="tot" >Account Balance&nbsp;:</div><div> $  <CurrencyFormat displayType={'text'} thousandSeparator={true} value={truncateDecimals(parseFloat(truncateDecimals(available, 2)) + parseFloat(truncateDecimals(tradedata?.actStackToTalAmou, 2)), 2)} /></div>
            </p>
          </li>
          <li className="nav-item">
            <NavLink
              className="nav-link text-nowrap"
              aria-current="page"
              to={isTrade ? "/trade" : "/"}
              onClick={handleTrade}
            >
              Trade
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to={isStake ? "/staking" : "/"} className="nav-link" aria-current="page" onClick={handleStake}
            >
              Staking
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/wallet" className="nav-link">
              Wallet
            </NavLink>
          </li>

          <li className="nav-item">
            <NavLink to="/transaction-history" className="nav-link text-nowrap">
              Transactions
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className="nav-link text-nowrap"
              to="/stakinghistory"
            >
              Staking History
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to={isLiveEvents ? "/live-events" : "/"} onClick={handleLiveEvent}>
              Live Events
            </NavLink>
          </li>
          {/* <li className="nav-item">
              <NavLink to="/referral" className="nav-link">
                Referrals
              </NavLink>
            </li> */}
        </ul>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasNavbar"
          aria-controls="offcanvasNavbar"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="offcanvas offcanvas-end"
          tabIndex={-1}
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
        >
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasNavbarLabel"></h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body">
            <div className="ms-auto">
              <ul class="navbar-nav mb-2 mb-xl-0 nav_after_login d-none d-xl-flex">
                <li class="nav-item">
                  <Link class="nav-link" to="/notifications">
                    <span className="notify_active">{notifiyCount}</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="20"
                      viewBox="0 0 448 512"
                    >
                      <path d="M224 0c-17.7 0-32 14.3-32 32V51.2C119 66 64 130.6 64 208v18.8c0 47-17.3 92.4-48.5 127.6l-7.4 8.3c-8.4 9.4-10.4 22.9-5.3 34.4S19.4 416 32 416H416c12.6 0 24-7.4 29.2-18.9s3.1-25-5.3-34.4l-7.4-8.3C401.3 319.2 384 273.9 384 226.8V208c0-77.4-55-142-128-156.8V32c0-17.7-14.3-32-32-32zm45.3 493.3c12-12 18.7-28.3 18.7-45.3H224 160c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7z" />
                    </svg>
                  </Link>
                </li>
                <li class="nav-item dropdown">
                  <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="20"
                      viewBox="0 0 18 20"
                      fill="none"
                    >
                      <path
                        d="M4.10256 4.87179C4.10256 2.18564 6.28821 0 8.97436 0C11.6605 0 13.8462 2.18564 13.8462 4.87179C13.8462 7.55795 11.6605 9.74359 8.97436 9.74359C6.28821 9.74359 4.10256 7.55795 4.10256 4.87179ZM12.0513 11.2821H5.89744C2.64615 11.2821 0 13.9282 0 17.1795C0 18.7354 1.26462 20 2.82051 20H15.1282C16.6841 20 17.9487 18.7354 17.9487 17.1795C17.9487 13.9282 15.3026 11.2821 12.0513 11.2821Z"
                        fill="#1E1E1E"
                      />
                    </svg>
                    My Account
                  </a>
                  <ul class="dropdown-menu">
                    <li>
                      <Link class="dropdown-item" to="/account-settings">
                        Account Settings
                      </Link>
                    </li>
                    <li>
                      <Link class="dropdown-item" to="/support-ticket">
                        Support Ticket
                      </Link>
                    </li>
                    <li>
                      <Link class="dropdown-item">
                        <span onClick={() => setProfileModal(true)}>
                          Edit Profile
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        class="dropdown-item"
                        onClick={() => logout(navigate, dispatch)}
                      >
                        Logout
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul class="navbar-nav mb-4 mb-xl-0 nav_after_login box d-xl-none">
                <li className="nav-item d-flex align-items-center me-xl-3 me-xxl-5">
                  <p className="mb-0 bal">
                    <div className="tot" > Total Available Balance&nbsp;:</div><div> $  <CurrencyFormat displayType={'text'} prefix={"$"} thousandSeparator={true} value={truncateDecimals(available, 2)} /></div>
                  </p>
                </li>
                {" "}
                <li className="nav-item d-flex align-items-center me-xl-3 me-xxl-5">
                  <p className="mb-0 bal">
                    <div className="tot" > Total Staked Balance&nbsp;:</div><div> $  <CurrencyFormat displayType={'text'} prefix={"$"} thousandSeparator={true} value={truncateDecimals(tradedata?.actStackToTalAmou, 2)} /></div>
                  </p>
                </li>
                {" "}
                <li className="nav-item d-flex align-items-center me-auto">
                  <p className="mb-0 bal">
                    <div className="tot" > Total Account Balance&nbsp;:</div><div> $  <CurrencyFormat displayType={'text'} prefix={"$"} thousandSeparator={true} value={truncateDecimals(available + tradedata?.actStackToTalAmou, 2)} /></div>
                  </p>
                </li>
              </ul>
              <ul class="navbar-nav mb-2 mb-xl-0 nav_after_login d-xl-none">
                {/* <li className="nav-item">
                  <p className="mb-0 bal nav-link text-center">
                    Total Balance : ${toFixedDown(available + lockBal, 4)}
                  </p>
                </li> */}

                <li className="nav-item">
                  <NavLink to={isTrade ? "/trade" : "/"} className="nav-link" onClick={handleTrade}>
                    Trade
                  </NavLink>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to={isTrade ? "/staking" : "/"} onClick={handleStake}>
                    Staking
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/wallet" className="nav-link">
                    Wallet
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/transaction-history" className="nav-link">
                    Transactions
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/stakinghistory" className="nav-link">
                    Staking History
                  </Link>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to={isLiveEvents ? "/live-events" : "/"} onClick={handleLiveEvent}>
                    Live Events
                  </NavLink>
                </li>
                <li class="nav-item">
                  <Link class="nav-link" to="/account-settings">
                    Account Settings
                  </Link>
                </li>
                <li class="nav-item">
                  <Link class="nav-link" to="/support-ticket">
                    Support Ticket
                  </Link>
                </li>
                <li class="nav-item">
                  <Link class="nav-link" to="/notifications">
                    Notifications
                    <span className="notify_active">{notifiyCount}</span>
                  </Link>
                </li>
                <li class="nav-item">
                  <Link
                    class="nav-link"
                    onClick={() => logout(navigate, dispatch)}
                  >
                    Logout
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavbarInner;
